import React from "react";
import { Heading } from "components/Heading";
import Swal from "sweetalert2";

const RefundPolicy = () => {
  return (
    <div className="container-xs p-8 space-y-6 sm:p-6">
      <h1 className="text-4xl text-center mb-2">
        Refund and Cancellation Policy
      </h1>
      <h3 className="text-xl font-semibold text-center">
        (Effective Date: 25-09-2024)
      </h3>
      <p className="pt-6">
        Thank you for choosing Soomuch Smart Solutions Private Limited. We
        strive to provide the best software services to educational institutions
        through our platform, www.soomuch.ai. Below is our refund and
        cancellation policy for the services provided.
      </p>
      <h2 className="text-3xl">1. Refund Policy</h2>
      <p className="text-xl">
        We offer a refund for our services under the following conditions:
      </p>
      <h2 className="text-2xl">Eligibility for Refund:</h2>
      <p>
        Refunds will only be processed if the request is made within 15 working
        days from the date of service activation or purchase. No refunds will be
        entertained after this period.
      </p>
      <h2 className="text-2xl">How to Request a Refund:</h2>
      <p>
        To request a refund, please contact our support team via email at
        [support@soomuch.ai]. You must provide the service details, purchase
        receipt, and the reason for requesting the refund.
      </p>
      <h2 className="text-2xl">Processing Time:</h2>
      <p>
        Refunds will be processed within 7 to 10 working days after the refund
        request is approved. The amount will be credited back to the original
        payment method.
      </p>

      <h2 className="text-2xl">Non-Refundable Services:</h2>
      <p>
        Any services that have been fully delivered or consumed, or custom
        services developed specifically for your institution, are
        non-refundable.
      </p>

      <h2 className="text-3xl">2. Cancellation Policy</h2>
      <h2 className="text-2xl">Cancellation of Service:</h2>
      <p>
        You can cancel your subscription or any ongoing services by notifying us
        at [support@soomuch.ai]. Upon successful cancellation, no further
        charges will apply. However, services already availed during the billing
        cycle will not be refunded.
      </p>
      <h2 className="text-2xl">Termination by Us:</h2>
      <p>
        Soomuch Smart Solutions Private Limited reserves the right to terminate
        any service in case of violation of our terms of service or misuse of
        the platform. In such cases, no refunds will be provided.
      </p>
      <h2 className="text-3xl">3. Changes to the Policy</h2>
      <p>
        We reserve the right to modify this refund and cancellation policy at
        any time. Any changes will be updated on our website www.soomuch.ai, and
        will become effective immediately upon posting.
      </p>

      <p className="my-5 font-bold">
        Note: For any queries or issues regarding the policy, feel free to reach
        out to us at support@soomuch.ai.
      </p>
    </div>
  );
};

export default RefundPolicy;
