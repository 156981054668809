import React from "react";
import { Heading } from "components/Heading";
import Swal from "sweetalert2";

const Privacy = () => {
  return (
    <div className="container-xs p-8 space-y-6 sm:p-6">
      <h1 className="text-4xl text-center mb-8">Terms and Conditions</h1>
      <h2 className="text-2xl">Introduction</h2>
      <p>
        Welcome to SOOMUCH Smart Solutions Private Limited. By accessing or
        using our services, you agree to be bound by these Terms and Conditions.
        If you do not agree with any part of these terms, you must not use our
        services.
      </p>
      <h2 className="text-2xl">Definitions</h2>
      <ul className="list-disc list-inside space-y-3">
        <li>
          <strong>"We," "Us," "Our"</strong> refers to SOOMUCH Smart Solutions
          Private Limited.
        </li>
        <li>
          <strong>"You," "Your"</strong> refers to the user or subscriber of our
          services.
        </li>
      </ul>
      <h2 className="text-2xl">Services</h2>
      <p>
        We provide various digital solutions for educational institutions,
        including, but not limited to, AUTO CONNECT, SOCIAL HUB, Parental
        Insight, Auto Fee Hub, and AI-Powered Management Hub.
      </p>

      <h2 className="text-2xl">User Responsibilities</h2>
      <ul className="list-disc list-inside space-y-3">
        <li>You agree to use our services only for lawful purposes.</li>
        <li>
          You must not misuse our services or interfere with their proper
          functioning.
        </li>
        <li>
          You are responsible for maintaining the confidentiality of your
          account information.
        </li>
      </ul>

      <h2 className="text-2xl">Intellectual Property</h2>
      <p>
        All content, trademarks, and data on our website and services are the
        property of SOOMUCH Smart Solutions Private Limited. You are not allowed
        to reproduce, distribute, or create derivative works without our
        explicit consent.
      </p>

      <h2 className="text-2xl">Limitation of Liability</h2>
      <p>
        We strive to provide the best services but do not guarantee error-free
        performance. We shall not be liable for any indirect, incidental, or
        consequential damages arising from the use or inability to use our
        services.
      </p>

      <h2 className="text-2xl">Termination</h2>
      <p>
        We reserve the right to terminate or suspend access to our services
        immediately, without prior notice or liability, for any reason
        whatsoever, including, without limitation, if you breach the Terms.
      </p>

      <h2 className="text-2xl">Governing Law</h2>
      <p>
        These Terms shall be governed and construed in accordance with the laws
        of India, without regard to its conflict of law provisions. Any disputes
        arising under or in connection with these Terms shall be subject to the
        exclusive jurisdiction of the courts in Hyderabad, Telangana, India.
      </p>

      <h1 className="text-3xl" id="terms-of-service">
        Terms of Service
      </h1>
      <h2 className="text-2xl">Acceptance of Terms</h2>
      <p>
        By accessing and using the services of SOOMUCH Smart Solutions Private
        Limited, you accept and agree to be bound by the terms and provisions of
        this agreement.
      </p>

      <h2 className="text-2xl">Description of Services</h2>
      <p>
        We offer a range of digital solutions tailored for educational
        institutions, designed to enhance operational efficiency and
        communication.
      </p>

      <h2 className="text-2xl">User Conduct</h2>
      <ul className="list-disc list-inside space-y-3">
        <li>You must provide accurate information during registration.</li>
        <li>
          You must not use the service for any fraudulent or illegal activities.
        </li>
      </ul>

      <h2 className="text-2xl">Payment Terms</h2>
      <ul className="list-disc list-inside space-y-3">
        <li>Subscription fees must be paid in advance.</li>
        <li>
          We reserve the right to change our subscription fees with prior
          notice.
        </li>
      </ul>

      <h2 className="text-2xl">Refund Policy</h2>
      <p>
        Due to the nature of digital services, we do not offer refunds once the
        service has been accessed for more than 15 days.
      </p>

      <h2 className="text-2xl">Service Availability</h2>
      <p>
        We do not guarantee uninterrupted availability of our services and
        reserve the right to modify or discontinue services with or without
        notice.
      </p>

      <h1 className="text-3xl" id="privacy-policy">
        Privacy Policy
      </h1>
      <h2 className="text-2xl">Information Collection</h2>
      <p>
        We collect personal information such as name, email address, and contact
        details when you register for our services.
      </p>

      <h2 className="text-2xl">Use of Information</h2>
      <ul className="list-disc list-inside space-y-3">
        <li>To provide and improve our services.</li>
        <li>
          To communicate with you regarding service updates and promotional
          offers.
        </li>
      </ul>

      <h2 className="text-2xl">Data Protection</h2>
      <p>
        We implement stringent security measures to protect your personal
        information. However, we cannot guarantee absolute security due to
        potential risks inherent in internet use.
      </p>

      <h2 className="text-2xl">Third-Party Disclosure</h2>
      <p>
        We do not sell, trade, or otherwise transfer your personally
        identifiable information to outside parties without your consent.
      </p>

      <h2 className="text-2xl">Cookies</h2>
      <p>
        We use cookies to enhance user experience and gather data about site
        traffic and interactions. You can choose to disable cookies through your
        browser settings.
      </p>

      <h2 className="text-2xl">Changes to Privacy Policy</h2>
      <p>
        We reserve the right to update this privacy policy at any time. We will
        notify you of any changes by posting the new policy on our website.
      </p>

      <h1 className="text-3xl" id="cookie-policy">
        Cookies Policy
      </h1>
      <h2 className="text-2xl">Use of Cookies</h2>
      <p>
        Our website uses cookies to distinguish you from other users, providing
        you with a better browsing experience and helping us improve our site.
      </p>

      <h2 className="text-2xl">Types of Cookies We Use</h2>
      <ul className="list-disc list-inside space-y-3">
        <li>
          <strong>Essential Cookies:</strong> Necessary for the operation of our
          website.
        </li>
        <li>
          <strong>Analytical/Performance Cookies:</strong> Allow us to recognize
          and count the number of visitors and see how visitors move around our
          website.
        </li>
        <li>
          <strong>Functionality Cookies:</strong> Used to recognize you when you
          return to our website.
        </li>
      </ul>

      <h2 className="text-2xl">Managing Cookies</h2>
      <p>
        You can control and/or delete cookies as you wish. For detailed
        guidance, visit aboutcookies.org. If you choose to disable cookies, some
        parts of our website may not function properly.
      </p>
      <h2 className="text-2xl">Changes to This Policy</h2>
      <p>
        We may update our cookies policy from time to time. Any changes will be
        posted on this page.
      </p>
    </div>
  );
};

export default Privacy;
